import React from 'react'
import { Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import BgSection from '../components/BgSection'
import WorkTogether from '../components/WorkTogether';

// Assets
import macbook from '../assets/icons/macbook.svg'
import phone from '../assets/icons/phone.svg'
import appleLogo from '../assets/icons/apple.logo.svg'
import playstoreLogo from '../assets/icons/playstore-logo.svg'
import globe from '../assets/icons/globe.svg'
import githubLogo from '../assets/icons/github-logo.svg'
import peacock from '../assets/images/peacock.svg'

// Data
import { getProjectBySlug } from '../data/projects/'
import Card from '../components/Card';


export default function Project() {
    const { slug } = useParams();
    const project = getProjectBySlug(slug);
    if (!project) return <Navigate to='/projects' replace />

    // Determine the device icon based on the platform.
    let device = null
    if (project.platform === 'web') device = macbook
    if (project.platform === 'mobile') device = phone

    return (
        <>
            <Helmet>
                <title>{project.title} | Mees Akveld</title>
                <meta name="twitter:title" content={`${project.title} | Mees Akveld`} />
				<meta property="og:title" content={`${project.title} | Mees Akveld`} />
                <meta name="twitter:image" content={`https://www.meesakveld.be${project.images.banner.src}`} />
                <meta property="og:image" content={`https://www.meesakveld.be${project.images.banner.src}`} />
            </Helmet>

            <div className='relative'>
                <div className='h-[20vw] min-[560px]:h-[30vw] relative -z-10'>
                    <img className='w-full h-[43vw] rounded-t-[30px] object-cover' src={project.images.banner.src} alt={project.images.banner.alt} />
                </div>

                <div className='mx-8 my-4 flex gap-2'>

                    {/* Platform */}
                    {device &&
                        <div title={project.platform} className='w-10 h-10 flex flex-shrink-0 bg-white p-2 justify-center rounded-full aspect-square'>
                            <img className='h-full' src={device} alt={project.platform} />
                        </div>
                    }

                    {/* App Store Url — Visible if: platform is mobile and mobile.appStoreUrl is not null */}
                    {project.platform === 'mobile' && project.mobile.appStoreUrl &&
                        <a className='hover:opacity-80 transition-opacity duration-300' title={`View ${project.title} on the App Store`} href={project.mobile.appStoreUrl} target="_blank" rel="noreferrer">
                            <div className='w-10 h-10 flex flex-shrink-0 bg-white p-2 justify-center rounded-full aspect-square'>
                                <img className='h-full' src={appleLogo} alt="App Store Logo" />
                            </div>
                        </a>
                    }

                    {/* Play Store Url — Visible if: platform is mobile and mobile.playStoreUrl is not null */}
                    {project.platform === 'mobile' && project.mobile.playStoreUrl &&
                        <a className='hover:opacity-80 transition-opacity duration-300' title={`View ${project.title} on the Play Store`} href={project.mobile.playStoreUrl} target="_blank" rel="noreferrer">
                            <div className='w-10 h-10 flex flex-shrink-0 bg-white p-2 justify-center rounded-full aspect-square'>
                                <img className='h-full' src={playstoreLogo} alt="Google Play Store Logo" />
                            </div>
                        </a>
                    }

                    {/* Web Url — Visible if: platform is web and web.url is not null */}
                    {project.platform === 'web' && project.web.url &&
                        <a className='hover:opacity-80 transition-opacity duration-300' href={project.web.url} target="_blank" rel="noreferrer">
                            <div className='w-10 h-10 flex flex-shrink-0 bg-white p-2 justify-center rounded-full aspect-square'>
                                <img className='h-full' src={globe} alt="Web Url" />
                            </div>
                        </a>
                    }

                    {/* Github Url — Visible if: githubUrl is not null */}
                    {project.githubUrl &&
                        <a className='hover:opacity-80 transition-opacity duration-300' href={project.githubUrl} target="_blank" rel="noreferrer">
                            <div className='w-10 h-10 flex flex-shrink-0 bg-white p-2 justify-center rounded-full aspect-square'>
                                <img className='h-full' src={githubLogo} alt="Github Url" />
                            </div>
                        </a>
                    }

                </div>

                <BgSection className="flex flex-col gap-20 sm:gap-32">

                    <div className='pt-8'>

                        <h1 className='uppercase font-passionOne text-8vw text-darkBlue'>{project.title}</h1>

                        <div className="mt-8 flex flex-col sm:flex-row border-lightGray border-y-[1px]">

                            {/* Introduction */}
                            <div className='py-16 sm:pr-16 flex flex-col gap-4 sm:w-3/5'>
                                <h2 className='hidden'>Introduction</h2>
                                {project.description.map((paragraph, index) => (
                                    <p key={`introduction-${index}`} className='text-lg text-gray-500 leading-[170%]' dangerouslySetInnerHTML={{ __html: paragraph }} />
                                ))}
                            </div>

                            {/* Development Notes */}
                            <ol className='py-16 sm:pl-16 flex flex-col gap-4 max-sm:border-t-[1px] sm:border-l-[1px] border-lightGray relative sm:w-2/5'>
                                <h2 className='uppercase rounded-[30px] bg-blue text-white py-2 px-6 text-[12px] absolute -top-6 sm:-top-4 left-1 sm:-left-12 -rotate-6 sm:-rotate-12'>Development notes</h2>
                                {project.developmentNotes.map((note, index) => (
                                    <li key={`development-note-${index}`}><p className='leading-[170%]'><span className='font-medium' dangerouslySetInnerHTML={{ __html: note.title }} />: <span dangerouslySetInnerHTML={{ __html: note.text}} /></p></li>
                                ))}
                            </ol>

                        </div>

                    </div>

                    <Card className="flex flex-col gap-8">
                        {project.images.showcaseWide.map((image, index) => (
                            <img key={`showcase-wide-${index}`} className='w-full' src={image.src} alt={image.alt} />
                        ))}
                    </Card>

                    <span></span>

                </BgSection>

                <div data-scroll data-scroll-speed="1.25" className='h-[40vw] min-[1000px]:h-[60vh] relative -top-20 min-[600px]:-top-36 -z-10' >
                    <img
                        className='w-full object-cover'
                        src={project.images.devider.src}
                        alt={project.images.devider.alt}
                    />
                </div>

                <BgSection className="flex flex-col gap-32">

                    <div className="mt-16 flex flex-col sm:flex-row border-lightGray border-y-[1px]">

                        {/* Most proud of */}
                        <div className='py-16 sm:pr-16 flex flex-col gap-4 justify-center relative sm:w-2/3'>
                            <h2 className='uppercase rounded-[30px] bg-blue text-white py-2 px-6 text-[12px] absolute -top-6 sm:-top-4 -right-1 sm:-right-12 rotate-6 sm:rotate-12'>Most proud of</h2>
                            {project.mostProudOf.map((paragraph, index) => (
                                <p key={`introduction-${index}`} className='text-lg text-gray-500 leading-[170%]' dangerouslySetInnerHTML={{ __html: paragraph }} />
                            ))}
                        </div>

                        <div className='p-12 flex flex-col items-center sm:border-l-[1px] border-lightGray sm:w-1/3'>
                            <img className='p-4 m-auto' src={peacock} alt="Most proud of" />
                        </div>

                    </div>

                    <Card>
                        <div className='flex flex-col md:flex-row py-8 w-full justify-between gap-y-8 gap-x-28'>
                            <span className='hidden md:pt-[100px]'></span>
                            <span className='hidden md:pt-[200px]'></span>
                            {project.images.mobileView.map((image, index) => (
                                <div>
                                    <img key={`mobile-view-${index}`} className={`w-full object-contain md:pt-[${index * 100}px] max-md:w-full`} src={image.src} alt={image.alt} />
                                </div>
                            ))}
                        </div>

                    </Card>

                    <WorkTogether />

                </BgSection>

            </div>

        </>
    )
}
