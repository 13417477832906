import React from 'react'

/**
 * Renders a title component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the component.
 * @param {string} props.titlePrt1 - The first part of the title (left side).
 * @param {string} props.titlePrt2 - The second part of the title (right side).
 * @param {string} props.titleH - The HTML heading tag to be used for the title. Defaults to 'h1'.
 * @param {string} props.imgPath - The path to the image.
 * @returns {JSX.Element} The rendered title component.
 */
export default function Title({title, titlePrt1, titlePrt2, titleH, imgPath, imgClassName=""}) {
    const HeadingTag = titleH || 'h1';

    return (
        <div title={title}>
            <HeadingTag className="hidden">{title}</HeadingTag>
            <div className='relative pointer-events-none'>
                <p className="font-passionOne text-darkBlue uppercase text-22vw leading-[22vw] absolute">{titlePrt1}</p>
                <p className="font-passionOne text-darkBlue uppercase text-22vw leading-[22vw] absolute bottom-0 right-0">{titlePrt2}</p>

                <img data-scroll data-scroll-speed="1.25" className={`mix-blend-multiply w-full max-w-4/5 m-auto ${imgClassName}`} src={imgPath} alt={`${title} title image`} />
            </div>
        </div>
    )
}
