import card from '../../assets/images/projects/green-guide/card.png';
import banner from '../../assets/images/projects/green-guide/banner.png';
import showcaseWide1 from '../../assets/images/projects/green-guide/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/green-guide/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/green-guide/showcase-wide-3.png';
import devider from '../../assets/images/projects/green-guide/devider.png';
import mobile1 from '../../assets/images/projects/green-guide/mobile-1.png';
import mobile2 from '../../assets/images/projects/green-guide/mobile-2.png';
import mobile3 from '../../assets/images/projects/green-guide/mobile-3.png';

export default {
    title: "Green Guide",
    slug: 'green-guide',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: "https://apps.apple.com/us/app/green-guide-gent/id6741322104",
        playStoreUrl: "https://play.google.com/store/apps/details?id=be.codingwizards.greenguide"
    },
    web: {
        url: null,
    },
    description: [
        `<strong>The Green Guide</strong> is an initiative by Artevelde University of Applied Sciences, University of Ghent, HOGENT, KU Leuven (Ghent), LUCA School of Arts, Odisee, and visitgent.`,
        `<strong>The Green Guide</strong> is a mobile application that promotes sustainable living in Ghent, featuring <strong>eco-friendly restaurants, shops, green transport options, and recycling tips</strong>. It connects <strong>inspiring businesses and organizations</strong>, offering a <strong>loyalty card</strong> with access to promotions and fostering a <strong>climate-friendly, zero-waste, and circular lifestyle</strong>.`,
        `I've designed and developed the app, focusing on creating a <strong>user-friendly experience</strong> that is both <strong>engaging and informative</strong>. I've used <strong>React Native</strong> to build the app, integrating it with a <strong>headless CMS</strong> to manage content and data. The app uses <strong>token-based authentication</strong> to ensure secure access to user data.`,
        `The app offers a <strong>two-flow experience</strong>, one for the general public and one for the businesses. The general public can use the app to <strong>discover eco-friendly businesses, access promotions, and learn about sustainable living</strong>. Businesses can use the app to <strong>create and manage promotions and articles</strong> and <strong>view analytics</strong> on their performance.`,
    ],
    developmentNotes: [
        { title: "React Native", text: "The app is developed using React Native, a popular framework for building mobile applications on both iOS and Android." },
        { title: "Strapi", text: "The app uses Strapi as a headless CMS to manage content and data." },
        { title: "Axios", text: "All data is fetched using Axios, to easily use and update token-based authentication." },
        { title: "React Query", text: "All data in the app is managed with React Query, ensuring optimal performance by leveraging caching to minimize API calls." },
        { title: "NativeWind", text: "The app uses NativeWind to style components, making the development process faster and more efficient." },
        { title: "Socket.io", text: "The app uses Socket.io to enable real-time communication between the app and Strapi. This is use to provide a confirmation when a user redeems a promotion on both the user and business side." },
    ],
    mostProudOf: [
        `<strong>I'm most proud</strong> of how the app utilizes a <strong>two-flow experience</strong>, offering a <strong>unique experience</strong> for both the general public and businesses. And keeping repeating code to a minimum by using <strong>components</strong> and <strong>hooks</strong>. Because I utilized <strong>components</strong> and <strong>hooks</strong>, I was able to keep the <strong>codebase clean and organized</strong>, making it easier to maintain and update the app.`,
        `<strong>I'm also proud</strong> of how I implemented <strong>Socket.io</strong> to enable <strong>real-time communication</strong> between the app and Strapi. This was a <strong>challenge to implement</strong>, but it was very rewarding to see it work.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}