import card from '../../assets/images/projects/wishflow/card.png';
import banner from '../../assets/images/projects/wishflow/banner.png';
import showcaseWide1 from '../../assets/images/projects/wishflow/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/wishflow/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/wishflow/showcase-wide-3.png';
import devider from '../../assets/images/projects/wishflow/devider.png';
import mobile1 from '../../assets/images/projects/wishflow/mobile-1.png';
import mobile2 from '../../assets/images/projects/wishflow/mobile-2.png';
import mobile3 from '../../assets/images/projects/wishflow/mobile-3.png';

export default {
    title: "Wishflow",
    slug: 'wishflow',
    platform: 'mobile', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: null,
        playStoreUrl: null,
    },
    web: {
        url: null,
    },
    description: [
        `<strong>Wishflow</strong> makes it easy to manage <strong>wish lists and gift groups</strong> for any occasion. With features like retrieving gift data and gift assignments, users can prevent duplicate presents and ensure a smooth gifting experience.`,
        `The app is designed to be <strong>intuitive and efficient</strong>, allowing users to <strong>create, edit, and organize wish lists</strong> effortlessly. Users can also <strong>add wishes using a URL</strong>, which automatically fetches metadata such as title, image, and price if available from the JSON-LD, OpenGraph or title tags of the page. This feature is particularly useful for users who want to quickly add items from online stores or product pages.`,
        `Built with <strong>SwiftUI</strong> for iOS, the app integrates with a <strong>Strapi backend</strong> to manage content and user data. It also leverages <strong>native iOS features</strong> like deep linking, calendar integration, notifications, and Spotlight Quick Actions for a seamless user experience.`
    ],
    developmentNotes: [
        { title: "SwiftUI", text: "The app is developed using SwiftUI, Apple's modern framework for building iOS applications." },
        { title: "Strapi", text: "Strapi is used as the headless CMS to manage all backend data, providing flexibility and easy content management." },
        { title: "PostgreSQL", text: "The app uses PostgreSQL as the database, hosted on Aiven.io." },
        { title: '<a target="_blank" href="https://github.com/meesakveld/strapi-swift" style="text-decoration: underline;">StrapiSwift</a>', text: "All API communication is handled via StrapiSwift, a <strong>homemade</strong> Swift package that simplifies working with Strapi APIs." },
        { title: "SwiftSoup", text: "To parse HTML from URLs in Swift." },
        { title: "Push Notifications", text: "Notifications are powered by @parse/node-apn, allowing users to stay updated on events and wish lists." }
    ],
    mostProudOf: [
        `<strong>I'm most proud</strong> of how the app simplifies <strong>wish list and gift management</strong> with an intuitive and elegant user experience. Features like <strong>gift assignments</strong> and <strong>metadata retrieval from URLs</strong> make the app truly useful for users planning gift exchanges.`,
        `<strong>I'm also proud</strong> of the seamless integration of <strong>native iOS features</strong> such as deep linking, Spotlight Quick Actions, and calendar integration, making the app feel truly integrated with the iOS ecosystem.`
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}