import greenGuide from './green-guide';
import judoclubGoshin from './judoclub-goshin';
import cookAndShare from './cook-and-share';
import chapterOneBackoffice from './chapter-one-backoffice';
import railLink from './rail-link';
import structure from './structure';
import todaysDish from './todays-dish';
import studentTrackingSystem from './student-tracking-system';
import pgmPlatform from './pgm-platform';
import toDoApplication from './to-do-application';
import studioClean from './studio-clean';
import recipeBook from './recipe-book';
import gentseFeesten2023 from './gentse-feesten-2023';
import realtimeParkingGent from './realtime-parking-gent';
import multusTimers from './multus-timers';
import wishflow from './wishflow';

const projects = [
    wishflow,
    greenGuide,
    judoclubGoshin,
    cookAndShare,
    chapterOneBackoffice,
    railLink,
    structure,
    studentTrackingSystem,
    pgmPlatform,
    toDoApplication,
    studioClean,
    recipeBook,
    gentseFeesten2023,
    todaysDish,
    realtimeParkingGent,
    multusTimers
];

export default projects;

export const getProjectBySlug = (slug) => {
    return projects.find(project => project.slug === slug
)};